import artsy from "./assets/projectsImg/artsy.jpg";
import course from "./assets/projectsImg/course.jpg";
import crappo from "./assets/projectsImg/crappo.jpg";
import ency from "./assets/projectsImg/ency.jpg";
import getset from "./assets/projectsImg/getset.jpg";
import shortly from "./assets/projectsImg/shortly.jpg";
import space from "./assets/projectsImg/space.jpg";
import tipsy from "./assets/projectsImg/tipsy.jpg";
import tweet from "./assets/projectsImg/tweet.jpg";
import song from "./assets/projectsImg/song.jpg";
import candy from "./assets/projectsImg/candy.jpg";
import unify from "./assets/projectsImg/unify.jpg";
import owasp from "./assets/projectsImg/owasp.jpg";
import linkfree from "./assets/projectsImg/linkfree.jpg";

const images = {
  artsy,
  course,
  candy,
  owasp,
  linkfree,
  unify,
  crappo,
  ency,
  getset,
  shortly,
  space,
  tipsy,
  tweet,
  song,
};

function getImageByKey(key) {
  return images[key];
}

export default getImageByKey;
