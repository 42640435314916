export const data = {
  role: "Full Stack Developer",
  resumeLink:
    "https://drive.google.com/file/d/1Jt9AfU0GjBjyUUOpaKw21R0xdDNyIlQj/view?usp=sharing",
  devpost: "https://devpost.com/ManishBisht777",
  github: "http://github.com/manishbisht777",
  hashnode: "https://hashnode.com/@ManishBisht777",
  about: {
    bio: "Full Stack Developer experienced in developing web applications and REST APIs. Skilled in developing websites, Good problem-solving and debugging skills with a passion for learning emerging technologies.",
    education: [
      {
        name: "Bsc H Computer Science",
        location: "Keshav Mahavidayalaya , Delhi",
        date: "Sep 2023",
      },
      {
        name: "Senior Secondary",
        location: "R.P.V.V Yamuna Vihar, Delhi",
        date: "2018 - 2020",
      },
    ],
  },
  projects: [
    {
      name: "codetips",
      description:
        "Codetips sharing platform With nextjs, prisma mongodb and more",
      github: "https://github.com/ManishBisht777/codetips",
      live: "https://codetips-ebon.vercel.app/",
      image: "tipsy",
    },

    {
      name: "courseify",
      description: "Opensource website to share knowledge",
      github: "https://github.com/manishbisht777/courseify/",
      live: "https://courseify-omega.vercel.app/",
      image: "course",
    },

    {
      name: "Artsy",
      description: "Art Gallery Website with Live chat support",
      github: "https://github.com/ManishBisht777/ChatSupport",
      live: "https://arthaven.netlify.app/",
      image: "artsy",
    },
    {
      name: "Short Url",
      description: "A Url Shortening Ladning Page Using React And Tinyurl Api",
      github: "https://github.com/ManishBisht777/url-shortning",
      live: "https://make-url-small.netlify.app/",
      image: "shortly",
    },
    {
      name: "Space Visit",
      description: "A Space Visit Website Using React, React Router Outlet",
      github: "https://github.com/ManishBisht777/spacewebsite",
      live: "https://spacevisit.netlify.app/",
      image: "space",
    },
    {
      name: "Encyclopaedia",
      description: "A Encyclopaedia Webste Using React And Gsap",
      github: "https://github.com/ManishBisht777/encyclopedia",
      live: "https://goodencyclopedia.netlify.app/",
      image: "ency",
    },
    {
      name: "Getsetbuy",
      description: "A Ecommerce Webiste Made Using MERN Stack",
      github: "https://github.com/ManishBisht777/GetSetBuy",
      live: "https://getsetbuy.netlify.app/",
      image: "getset",
    },
    {
      name: "True Music",
      description:
        "Music App Using Spotify Api And React, Redux Toolkit and much more",
      github: "https://github.com/ManishBisht777/musica/",
      live: "https://tweetspace.vercel.app/",
      image: "song",
    },
    {
      name: "Tweet space",
      description:
        "Full stack app for organising all twitter spaces at one place",
      github: "http://github.com/manishbisht777/tweetspace/",
      live: "https://tweetspace.vercel.app/",
      image: "tweet",
    },
    {
      name: "Crappo",
      description: "A Crypto Webste Using React And Intersection Observer Api",
      github: "https://github.com/ManishBisht777/Crypto-Website",
      live: "https://crypto-website-gap.netlify.app/",
      image: "crappo",
    },
  ],
  socials: [
    { link: "http://github.com/manishbisht777", name: "Github" },
    { link: "https://leetcode.com/ManishBisht777/", name: "Leetcode" },
    { link: "https://twitter.com/manishbisht9711", name: "Twitter" },
    {
      link: "https://www.linkedin.com/in/manish-bisht-21b02b220/",
      name: "Linkedin",
    },
  ],
};

export const moreinfo = {
  devpost: "https://devpost.com/ManishBisht777",
  github: "http://github.com/manishbisht777",
  hackathons: [
    {
      name: "Stream Hackathon",
      project: "Artsy",
      desc: "An art exhibition website where you can view art from various artists with a live chat/support for any queries and meet people with similar interests as yours.",
      link: "https://devpost.com/software/artsy-04eoct",
      image: "artsy",
    },
    {
      name: "Hack for creators",
      project: "Earcandy",
      desc: "An audiobook platform where users can create and share their own audiobooks. This platform aims to provide a space for individuals to share their stories, and connect with individuals.",
      link: "https://devpost.com/software/earcandy",
      image: "candy",
    },
    {
      name: "Hackaround the world 2",
      project: "Unify",
      desc: "Unify was inspired by the desire to promote cultural diversity and sustainability while creating a unique and engaging online marketplace focused on community building.",
      link: "https://devpost.com/software/unify-nug0ke",
      image: "unify",
    },
  ],
  blogs: [
    {
      name: "Build your content-driven website now with Contentlayer and Nextjs",
      project: "Developement",
      desc: "",
      link: "https://manishbisht.hashnode.dev/build-your-content-driven-website-now-with-contentlayer-and-nextjs#clijt5w2d0owjamnv13keds2w",
      image: "course",
    },
  ],
  opensource: [
    {
      name: "Linkfree",
      desc: "A platform where people in tech can have a single hub to showcase their content in order to accelerate their career.",
      link: "https://github.com/EddieHubCommunity/LinkFree/pulls?q=is%3Apr+author%3AManishBisht777+is%3Aclosed",
      image: "linkfree",
    },
    {
      name: "Owasp",
      desc: "Report issues and get points, companies are held accountable.",
      link: "https://github.com/OWASP/BLT/pulls?q=is%3Apr+author%3AManishBisht777+is%3Aclosed",
      image: "owasp",
    },
  ],
};
